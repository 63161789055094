<template>
  <div class="releasenotes-page">
    <h2 class="text-center mb-4">Release Notes</h2>
    <template v-if="data && data.length">
      <div v-for="item in data" :key="item.id" class="releasenote-item">
        <p class="item-title">{{ item.title }}</p>
        <p class="item-date">{{ item.released_at }}</p>
        <div class="item-content" v-html="item.content"></div>
      </div>
    </template>
    <template v-else-if="!isLoading">
      No Content
    </template>
    <div class="d-flex justify-content-center" v-if="isLoading">
      <span class="atom-spinner-wrapper">
        <atom-spinner slot="loading" :animation-duration="1500" :size="80" color="rgb(53,120,198)"/>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
      query: {
        limit: 10,
        offset: 0,
        search: undefined,
      },
      isLoading: false,
      hasScrollListener: null,
      loadMore: true,
    }
  },

  mounted() {
    this.loadData()
    let contentWrapper = window
    if (!this.hasScrollListener) {
      this.hasScrollListener = true;
      contentWrapper.addEventListener("scroll", () => {
        let isBottom = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.documentElement.scrollHeight
        if ( isBottom && this.loadMore && !this.isLoading ) {
          this.loadData();
        }
      });
    }
  },

  methods: {
    loadData() {
      this.isLoading = true
      this.$store.dispatch('releaseNote/list', this.query)
        .then((res) => {
          this.isLoading = false
          this.afterLoad(res.data)
        })
        .catch((e) => {
          this.isLoading = false
        })
    },
    afterLoad(data) {
      if (data && data.length)
      {
        this.data = this.data.concat(data)
        this.query.offset = this.query.offset + data.length
        if (data.length < this.query.limit)
          this.loadMore = false
      } else {
        this.loadMore = false
      }
    }
  },

  computed: {
  },
}
</script>
<style lang="scss">
.releasenotes-page {
  .releasenote-item {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ccc;
    p {
      margin: 0;
    }
    a {
      color: $rb-blue;
      &:hover {
        text-decoration: underline;
      }
    }
    .item-title {
      font-weight: bold;
      font-size: 22px;
    }

    .item-date {
      font-style: italic;
      font-weight: 600;
      color: #999;
    }

    .item-content {
      margin: 20px 0 0 20px;

      ul {
        margin: 0;
        padding: 0;
        padding-left: 10px;
      }
    }
  }
}</style>
